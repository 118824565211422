export default function ({ route, store, redirect }) {
    if (process.server) {
        // First, check if the list from the redirects api contains the url
        const redirectData = store.state.data.redirects.find((x) => x.url === route.path);
        const qrCodeRedirectData = store.state.data.qrCodeRedirects.find((x) => '/redirect/qr/' + x.id === route.path);

        if (redirectData && /https:\/\//.test(redirectData.destination)) {
            return redirect(301, redirectData.destination);
        } else if (qrCodeRedirectData) {
            return redirect(301, qrCodeRedirectData.url);
        }

        // If not, redirect programmatically
        const staticRedirects = [
            {
                regExp: /^\/megujulo-varosliget/,
                target: '/projekt',
            },
            {
                regExp: /^\/en\/renewed-varosliget/,
                target: '/en/project',
            },
            {
                regExp: /^\/hirek/,
                target: '/magazin',
            },
            {
                regExp: /^\/en\/news/,
                target: '/en/magazine',
            },
            {
                regExp: /^\/cikkek/,
                target: '',
            },
            {
                regExp: /^\/articles/,
                target: '/en',
            },
        ];

        let redirectValue = null;

        staticRedirects.forEach((item) => {
            if (item.regExp.test(route.path)) {
                redirectValue = route.path.replace(item.regExp, item.target);
            }
        });

        if (redirectValue) {
            return redirect(301, redirectValue);
        }
    }
}
