<template>
    <div id="__layout__content" class="flex flex-col min-h-screen relative z-0">
        <PublicAnnouncements class="z-15" />
        <Header dark class="z-10" />
        <div
            class="relative flex-grow z-0 text-white bg-theme-dark bg-black"
            :style="`margin-top: ${announcementsCombinedHeight}`"
        >
            <Nuxt />
        </div>
        <Footer theme="dark" class="relative z-5" />
        <GridOverlay />
        <ScrollToTopButton class="z-50" />
    </div>
</template>

<script>
import Header from '@/components/Layout/Header';
import Footer from '@/components/Layout/Footer';
import GridOverlay from '@/components/Layout/GridOverlay';
import PublicAnnouncements from '@/components/Layout/PublicAnnouncements.vue';
import i18nMeta from '@/mixins/i18nMeta';
import announcement from '@/mixins/announcement';
import i18n from '@/mixins/i18n';
import ScrollToTopButton from '~/components/Layout/ScrollToTopButton.vue';

export default {
    name: 'DarkLayout',
    components: {
        ScrollToTopButton,
        PublicAnnouncements,
        Header,
        Footer,
        GridOverlay,
    },
    mixins: [i18nMeta, announcement, i18n],
    middleware: ['route-change', 'redirects'],
    mounted() {
        setTimeout(() => {
            this.$store.commit('layout/setIsImageLoadingBlocked', false);
        }, 2000);
    },
};
</script>

<style></style>
