<template>
    <div id="__layout__content" class="flex flex-col min-h-screen relative z-0">
        <PublicAnnouncements class="z-15" />
        <Header class="z-10" />
        <div class="relative flex-grow z-1" :style="`margin-top: ${announcementsCombinedHeight}`">
            <Nuxt />
        </div>
        <Footer v-if="!isFooterHidden" theme="light" class="relative z-0" />
        <GridOverlay />
        <ScrollToTopButton class="z-50" />
    </div>
</template>

<script>
import Header from '@/components/Layout/Header';
import Footer from '@/components/Layout/Footer';
import GridOverlay from '@/components/Layout/GridOverlay';
import ScrollToTopButton from '@/components/Layout/ScrollToTopButton.vue';
import PublicAnnouncements from '@/components/Layout/PublicAnnouncements.vue';
import i18nMeta from '@/mixins/i18nMeta';
import announcement from '@/mixins/announcement';
import i18n from '@/mixins/i18n';

export default {
    name: 'Default',
    components: {
        Header,
        Footer,
        GridOverlay,
        ScrollToTopButton,
        PublicAnnouncements,
    },
    mixins: [i18nMeta, announcement, i18n],
    middleware: ['route-change', 'redirects'],
    computed: {
        isFooterHidden() {
            return this.$route && this.$route.name && /^map___/.test(this.$route.name) && this.$device.isMobileOrTablet;
        },
    },
    mounted() {
        setTimeout(() => {
            this.$store.commit('layout/setIsImageLoadingBlocked', false);
        }, 2000);
    },
};
</script>

<style></style>
