export default function ({ getRouteBaseName, app }) {
    if (
        getRouteBaseName() !== 'index' &&
        !app.head.script.find((x) => x.src === 'https://js.hsforms.net/forms/v2.js')
    ) {
        app.head.script.push({
            src: 'https://js.hsforms.net/forms/v2.js',
        });
    }
}
